import ReactGA from 'react-ga';
import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Main from './components/introduction/Main';
import About from './components/About';
import Info from './components/Info';
import { AppContext } from "./context/AppContext";
import BasicPage from './components/AccountOpeningSteps/BasicPage';
import { useState } from 'react';
import Logo from './components/introduction/Logo';
import Thanks from './components/AccountOpeningSteps/Steps/Thanks';
import Docs from './components/introduction/Documents/Docs';
import { Toaster, toast } from 'react-hot-toast';
import GoogleAnalytics from './././components/AccountOpeningSteps/GoogleAnalytics';
import { useLocation } from "react-router-dom";


const TRACKING_ID = "1C82XD0SQ9"; 
ReactGA.initialize(TRACKING_ID);

const App = () => {



    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    
        const params = new URLSearchParams(location.search);
        if (params.has("ref")) {
          setRefFlag(1); // Set refFlag to 1 if "ref" exists
          localStorage.setItem("reference", "1"); // Save to localStorage
        } else if (!localStorage.getItem("reference")) {
          setRefFlag(0);
          localStorage.setItem("reference", "0");
        }
      }, [location]);
      
    const [step, setPage] = useState(1);
    const [api, setApi] = useState(1);
    const [account, setAccount] = useState('CLS');
    const [userData, setUserData] = useState([]);
    const [uin, setUin] = useState();
    const [tradercheck, setTraderCheck] = useState();
    const [refFlag, setRefFlag] = useState(0); // Initialize refFlag in the context


    
    const checkValidateFields = (obj) => {
        for (const [key, value] of Object.entries(obj)) {       
            // if(!value.trim()) return toast.error(`${formatString(key)} can\'t be empty!`);
        }
    };

    return (
        <AppContext.Provider value={{step,setPage,api,setApi,account,checkValidateFields, setAccount,userData, setUserData,uin, setUin, tradercheck, setTraderCheck}}>
                           <GoogleAnalytics />

            <Routes>
                <Route exact path='/' Component={Main} />
                <Route path='/info' Component={Info} />
                <Route exact path='/account-opening' Component={BasicPage} />
                <Route exact path='/submit' Component={Thanks} />
                <Route exact path = '/required-docs' Component={Docs} />
                <Route path="*" element={<Navigate to="/" />} />

            </Routes>
            <Toaster position="center" />
        </AppContext.Provider>
    )
}

export default App;

