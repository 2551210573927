import React from "react";
import { useState, useEffect } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from "@mui/material";
import Radio from '@mui/material/Radio';
import gonext from '../../../assets/white_right_arrow.png';
import goback from '../../../assets/back_arrow_brown.png'
import "../style.css";
import { AppContext } from '../../../context/AppContext';
import { useContext } from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import * as yup from 'yup';



function StepOne() {
  const [error, setError] = useState(false);
  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [previewFrontImage, setPreviewFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);
  const [previewBackImage, setPreviewBackImage] = useState(null);
  const [selectedRelationImage, setSelectedRelationImage] = useState(null);
  const [previewRelationImage, setPreviewRelationImage] = useState(null);
  const [errorss, setErrorr] = useState(null);

  const [relImg, setRelImg] = useState(null);
  const [frontImg, setFrontImg] = useState(null);
  const [backimg, setBackImg] = useState(null);  // to check if image is exist or not 



  const [relationImageError, setRelationImage] = useState(null);
  const [cnicFrontImage, setCnicFrontImage] = useState(null);
  const [cnicBackImage, setCnicBackImage] = useState(null);  // to show errors 
  const [regError , setRegError] = useState(null);  // to show errors 
  const [dateError , setDateError] = useState(null);  // to show errors 


  const [status, setStatus] = React.useState(1) // 0: no show, 1: show yes, 2: show no.

  // setStatus('1');
  const radioHandler = (status) => {
    setStatus(status);
    setInputFields({ ...inputFields, mob_registration: status });
  };


  const { set, setdat, checkValidateFields, api, setApi, account, setAccount, uin, setUin, trader , setTrader} = useContext(AppContext);
  const {tradercheck, setTraderCheck} = useContext(AppContext);

  const [inputFields, setInputFields] = useState({
    cnic: "",
    salutation: "",
    account_type: account,
    name: "",
    email: "",
    dob: "",
    mob_registration: "",
    phone: "",
    relative_Cnic: "",
    relative_relation: "",
    proof_of_relation: "",
    front_cnic: "",
    back_cnic: "",
    relation_proof: "",
    trader: "",
    branch: "",
    mother_name: "",
    gender: "",
    pob:"",
    reference: "", // Add the reference field

    

  });
  const schema = yup.object().shape({
    cnic: yup.string()
      .required()
      .matches(/^\d+$/, 'CNIC must be a number')
      // .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      .max(13)
      .min(13),
    salutation: yup.string()
      .required(),
      mother_name: yup.string()
      .required("Mother's name is required Field")
      .max(30)
      .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      .max(30),
    gender: yup.string()
      .required(),
    name: yup.string()
      .required()
      .max(30)
      .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed'),
    email: yup.string()
      .required(),
    dob: yup.string()
      .required('Date of birth is required')
      .max(Date.now(), 'Date should not be greater than the current date'),
    phone: yup.string()
      .required()
      .matches(/^\d+$/, 'Phone must be a number')
      .min(11)
      .max(11),
      pob: yup.string()
      .required('place of birth is required field')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      .min(2)
      .max(40),
    relative_relation: inputFields.mob_registration == 2 ? yup.string().required() : yup.string(),


  });
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cnic: inputFields.cnic, // Set initial value for 'cnic' field
      // Set initial values for other fields
    },
  });
  useEffect(() => {
    // Retrieve the reference value from local storage
    const referenceValue = localStorage.getItem("reference");
    if (referenceValue) {
      setInputFields((prevFields) => ({
        ...prevFields,
        reference: referenceValue,
      }));
    }
  }, []);

  useEffect(() => {
    // Reset the form when the 'inputFields' change to update the initial values
    reset({
      cnic: inputFields.cnic,
      salutation: inputFields.salutation,
      relative_Cnic: inputFields.relative_Cnic,
    });
  }, [inputFields, reset]);

  const handleFrontChange = (event) => {

    const file = event.target.files[0];

    const maxSizeInBytes = 2 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      setCnicFrontImage("File size should be less than 2 mb.");
      // Perform necessary error handling or display an error message to the user
      return;
    }


    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFrontImage(file);
        setInputFields({ ...inputFields, front_cnic: file });
        setPreviewFrontImage(reader.result);
        setFrontImg(null);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFrontImageDelete = () => {
    setSelectedFrontImage(null);
    setPreviewFrontImage(null);
    setErrorr(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      front_cnic: null,
    }));
  };
  const handleBackChange = (event) => {


    const file = event.target.files[0];


    const maxSizeInBytes = 2 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      setCnicBackImage("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedBackImage(file);
        setInputFields({ ...inputFields, back_cnic: file });
        setPreviewBackImage(reader.result);
        // setErrorr(iban);
        setBackImg(null);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleBackImageDelete = () => {
    setSelectedBackImage(null);
    setPreviewBackImage(null);
    setErrorr(null);
    setIsLoading(false);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      back_cnic: null,
    }));

    // inputFields.relation_proof(null);
  };
  const handleRelativeChange = (event) => {

    const file = event.target.files[0];

    const maxSizeInBytes = 2 * 1024 * 1024; // 10mb
    if (file.size > maxSizeInBytes) {
      setRelationImage("File size should be less than 2 mb");
      // Perform necessary error handling or display an error message to the user
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedRelationImage(file);
        setInputFields({ ...inputFields, relation_proof: file });
        setPreviewRelationImage(reader.result);
        // setErrorr(iban);
        setRelImg(null);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleRelativeImageDelete = () => {
    setSelectedRelationImage(null);
    setPreviewRelationImage(null);
    setErrorr(null);
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      relation_proof: null,
    }));
  };



  const errorRef = useRef(null);
  const errorRefFront = useRef(null);
  const errorRefBack = useRef(null);
  const errorReg = useRef(null);
  const errorDate = useRef(null);



  const [isLoading, setIsLoading] = useState(false);

  console.log(inputFields);

  const onSubmit = async (data) => {

    const dateOfBirth = new Date(data.dob);
    const today = new Date();
    const age = today.getFullYear() - dateOfBirth.getFullYear();

    if (age < 18) {
      setDateError("Age must be at least 18 years old.");
      errorDate.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }


    if (api == 1 || api >= 2) {

      if (!inputFields.mob_registration) {
        setRegError('Please select Mobile registration')
        // Perform necessary error handling or display an error message to the user
        errorReg.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      

      if (!inputFields.relation_proof && inputFields.mob_registration == 2) {
        setRelationImage('please upload proof of Relationship ')
        // Perform necessary error handling or display an error message to the user
        errorRef.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      if (!inputFields.front_cnic) {
        setCnicFrontImage('please upload front Cnic ')
        // Perform necessary error handling or display an error message to the user
        errorRefFront.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }
      if (!inputFields.back_cnic) {
        setCnicBackImage('please upload back Cnic')
        // Perform necessary error handling or display an error message to the user
        errorRefBack.current.scrollIntoView({ behavior: 'smooth' });

        return;
      }

      setIsLoading(true);

      axios.post('https://aofapi.bmatrade.com/api/stepone', inputFields, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data

        },

      })

        .then((response) => {
          console.log(response);
          setIsLoading(false);
          setApi(2);

          // Handle the response data
          setAlertMessage('API request succeeded!');

          // alert("data sent");
          if (step < 6) {
            const nextStep = step + 1;
            console.log(nextStep);
            setPage(nextStep)
          }
          setApi(2);
        })
        .catch((error) => {
         toast.error('network error');
          setIsLoading(false);

        })



    } else {
      // event.preventDefault();
      console.log('ds');
      toast.error(`please first proceed with step ${api}`);

    }
  };


  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };



  const [changeCount, setChangeCount] = useState(0);
  const { userData, setUserData } = useContext(AppContext);
  const [branches, SetBranches] = useState(['']);
  const [traders, setTraders] = useState([]);

  const fetchUserData = async (cnic) => {


    try {
      const { data } = await axios.get('https://aofapi.bmatrade.com/api/user-data/' + cnic);


      if (data.is_completed == '0') {
        const { App_DOB, Title, App_Name, Email, ContactCell, Mobile_reg, Relative_cnic, Relative_relation, branch_name, trader_name, App_Cnic_F_Image, App_Cnic_B_Image, relation_image , App_Gender, App_Mother_Name, birth_place} = data.data;
        // toast.success(data.message);
        setUserData(data.data);

        setInputFields(
          {
            ...inputFields,
            salutation: Title,
            account_type: account,
            name: App_Name,
            email: Email,
            cnic,
            dob: App_DOB,
            mob_registration: Mobile_reg,
            phone: ContactCell,
            relative_Cnic: Relative_cnic,
            relative_relation: Relative_relation,
            proof_of_relation: "",
            trader: trader_name,
            branch: branch_name,
            front_cnic: App_Cnic_F_Image,
            back_cnic: App_Cnic_B_Image,
            relation_proof: relation_image,
            gender:App_Gender,
            mother_name:App_Mother_Name,
            pob:birth_place

          })

        if (inputFields.relation_proof != null || inputFields.relation_proof != undefined) {
          setRelImg(inputFields.relation_proof)
          setSelectedRelationImage('1');

        }
        if (inputFields.front_cnic != null || inputFields.front_cnic != undefined) {
          setFrontImg(inputFields.front_cnic)
          setSelectedFrontImage('1');
        }
        if (inputFields.back_cnic != null || inputFields.back_cnic != undefined) {
          setBackImg(inputFields.back_cnic)
          setSelectedBackImage('1');
        }

      }
      else if (data.is_completed == '1') {
        toast.error('account is under process');
        setTimeout(function () {
          window.location.reload(false);
        }, 3000);
        return; // Add this line to exit the function after displaying the toaster
      }
      else {
        data.status(404).toast.error('some')
        // toast.error(data.message);

      }


      console.log(userData);

    }

    catch (error) {
      // if (error.response.status == 404){
      //   toast.error('something went wrong');
      if (error.response.status == 404) {
        console.log('something went wrong');

      }
    }
  };
  useEffect(() => {
    const branches = async () => {
      try {
        const response = await axios.get('https://aofapi.bmatrade.com/api/branches');
        console.log(response.data);
        SetBranches(response.data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    branches();
  }, []);

  const handleBranch = async (e) => {


    
    
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    const branch = e.target.value;
    console.log(branch);
     
    if(branch != null && branch != undefined){

      setTraderCheck('trader');
    }
    else{
      setTraderCheck('null');
    }
    
    await getTraderByBranch(branch);
  }
  const branch = inputFields.branch;


  useEffect(() => {
    if (branch !== '') {
      getTraderByBranch(branch);
    }
  }, [branch]);



  async function getTraderByBranch(branch) {
    try {
      const response = await axios.get(`https://aofapi.bmatrade.com/api/traders/${branch}`);
      const traders = response.data;
      setTraders(traders);

      // Do something with the cities data
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }







  // const formattedData = userData && {
  //   AppCnic: userData.App_Cnic,
  //   App_name: userData.App_Name,
  //   // Other fields
  // };
  


  const handleChangeCnic = (e) => {


    const value = e.target.value;
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });



    if (value.length == 13) {
      setUin(value);
      fetchUserData(value);
    }
  };



  //   const MyComponent = () => {
  //  const uin == uin={.}  
  useEffect(() => {
    if (uin !== undefined && uin.length === 13) {
      fetchUserData(uin);
    }
  }, [uin]);



  //   if (changeCount == 13)
  //   {
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get('http://sales-crm.test/api/cities/7');
  //         console.log(response);
  //       } catch (error) {
  //         console.log('Error:', error);
  //       }
  //     };

  //     fetchData();
  //   }, []);

  // }
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://aofapi.test',
  };

  const [alertMessage, setAlertMessage] = useState('');



  // const [dragging, setDragging] = useState(false);






  // const AdSubmit = async (e) => {
  //   e.preventDefault();
  //   try {

  //     const formData = new FormData();

  //     // Append form field values
  //     formData.append("App_Cnic", inputFields.cnic);
  //     formData.append("Account_Nature", inputFields.salutation);
  //     formData.append("App_Name", inputFields.name);
  //     formData.append("Email", inputFields.email);
  //     formData.append("App_DOB", inputFields.dob);
  //     formData.append("ContactCell", inputFields.mob_registration);
  //     formData.append("ContactCell", inputFields.phone);
  //     formData.append("inputFields.cnic", inputFields.relative_Cnic);
  //     formData.append("inputFields.cnic", inputFields.relative_relation);
  //     formData.append("inputFields.cnic", inputFields.proof_of_relation);
  //     formData.append("Front_Cnic", Front_Cnic);
  //     formData.append("Back_Cnic", Back_Cnic);

  //     const Front_Cnic = e.target.elements.FrontCnic.files;
  //     const Back_Cnic = e.target.elements.BackCnic.files;


  //     // for (let i = 0; i < files.length; i++) {
  //     //   formData.append("imageFiles", files[i]);
  //     // }

  //     const response = await axios.post(
  //       `${baseUrl}/fame/submit`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );


  //     console.log(response, "Post An Ad Response");
  //   } catch (err) {
  //     setError(err.response);
  //   }
  // };




  const finishSubmit = () => {
    console.log(inputFields);
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0 && submitting) {
  //     finishSubmit();
  //   }
  // }, [errors]);
  const { step, setPage } = useContext(AppContext);

  const handleinc = () => {
    // event.preventDefault();
    if (step < 6) {
      const nextStep = step + 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };
  const handledec = () => {
    // event.preventDefault();
    if (step > 1) {
      const nextStep = step - 1;
      console.log(nextStep);
      setPage(nextStep)
    }
  };
  const baseUrl = 'https://aofapi.bmatrade.com/public/images/';
  const fcnic = inputFields.front_cnic;
  const backcnic = inputFields.back_cnic;
  const relimgs = inputFields.relation_proof;


  const frontUrl = `${baseUrl}${fcnic}`;
  const backUrl = `${baseUrl}${backcnic}`;
  const relUrl = `${baseUrl}${relimgs}`;









  return (



    <form onSubmit={handleSubmit(onSubmit)}>
      {/* {Object.keys(errors).length === 0 && submitting ? (
        <span className="success">Successfully submitted ✓</span>
      ) : null } */}

      
      <div className=" container-fluid row col-md-12 mt-5 d-flex justify-content-center step-mob">

        {/* <div className="col-md-3">
      </div> */}
        <div className="col-md-6">
          
          <div className="row">
          <div className="col-md-6 mb-4">
            <TextField
            fullWidth
              error
              id="outlined-error"
              label="CNIC"
              name="cnic"
              {...register('cnic')}
              onChange={handleChangeCnic}
              value={inputFields.cnic} />
                        <p className="text-danger fs-6"> {errors.cnic && <p>{errors.cnic.message}</p>} </p>
          </div>
          
           <div class="col-md-6 mb-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"
                >
                  Salutation

                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="salutation"
                  {...register('salutation')}
                  value={inputFields.salutation}
                  label="Salutation"
                  onChange={handleChange}
                  error
                >

                  <MenuItem >Select</MenuItem>
                  <MenuItem value="Mr" >Mr</MenuItem>
                  <MenuItem value="Mrs" >Mrs</MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>
                </Select>
              </FormControl>

              <p className="text-danger fs-6"> {errors.salutation && <p>{errors.salutation.message}</p>} </p>

            </div>
          </div>


          <div class="row">
          <div class="col-md-6 mb-4">
              <TextField
                defaultValue={'yasi'}
                error
                id="outlined-error"
                label="Name"
                name="name"
                {...register('name')}

                onChange={handleChange}
                value={inputFields.name}
                // inputProps={{ color: 'yellow!important' }}
                fullWidth
              />
              <p className="text-danger fs-6"> {errors.name && <p>{errors.name.message}</p>} </p>

            </div>
            <div class="col-md-6 mb-4">
              <TextField
                error
                id="outlined-error"
                type="text"
                label="Mother's name"
                name="mother_name"
                {...register('mother_name')}
                onChange={handleChange}
                value={inputFields.mother_name}
                fullWidth
              />
              <p className="text-danger fs-6"> {errors.mother_name && <p>{errors.mother_name.message}</p>} </p>


            </div>
          </div>
          <div class="row">
          <div class="col-md-6 mb-4">
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"
                >
                  Gender

                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="gender"
                  {...register('gender')}
                  value={inputFields.gender}
                  label="Gender"
                  onChange={handleChange}
                  error
                >

                  <MenuItem >Select</MenuItem>
                  <MenuItem value="M" >Male</MenuItem>
                  <MenuItem value="F" >Female</MenuItem>
                </Select>
              </FormControl>

              
              <p className="text-danger fs-6"> {errors.gender && <p>{errors.gender.message}</p>} </p>


            </div>
            <div class="col-md-6 mb-4">
                          <TextField
                            error
                            id="outlined-error"
                            type="email"
                            label="Email"
                            name="email"
                            {...register('email')}
                            onChange={handleChange}
                            value={inputFields.email}
                            fullWidth
                          />
                          <p className="text-danger fs-6"> {errors.email && <p>{errors.email.message}</p>} </p>

                          </div>
            
          </div>
          <div class="row">
          <div class="col-md-6 mb-4">
              <TextField className="dis"
                sx={{ textAlign: 'right' }}
                error
                id="outlined-error"
                label="Date of birth"
                inputProps={{ maxLenght: '2', textAlign: 'right' }}
                name="dob"
                // {...register('dob', {

                // })}
                {...register('dob')}

                onChange={handleChange}
                value={inputFields.dob}
                type="date"
                fullWidth
              />
              
              {dateError && (
                    <p ref={errorDate} className="text-danger fs-6">{dateError}</p>
                  )}
              <p className="text-danger fs-6"> {errors.dob && <p>{errors.dob.message}</p>} </p>
              

            </div>
            <div class="col-md-6 mb-4">
              <TextField className="dis"
                sx={{ textAlign: 'right' }}
                error
                id="outlined-error"
                label="Place of birth"
                inputProps={{ maxLenght: '2', textAlign: 'right' }}
                name="pob"
                {...register('pob')}
                onChange={handleChange}
                value={inputFields.pob}
                type="text"
                fullWidth
              />
             
                               <p className="text-danger fs-6"> {errors.pob && <p>{errors.pob.message}</p>} </p>
              
            </div>          
          </div>
        
          
          {(account === 'PRE' || account === 'SAH') && (
            <>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"
                    >
                      Branch
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="branch"
                      value={inputFields.branch}
                      label="Branch"
                      onChange={handleBranch}
                      error
                    >
                      <MenuItem >Select</MenuItem>
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>{branch.branch_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div class="col-md-6 mb-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Trader</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name='trader'
                      label="Trader"
                      error
                      value={inputFields.trader}
                      onChange={handleChange}
                    >
                      <MenuItem value=''>select</MenuItem>
                      {traders.map((trader) => (
                        <MenuItem key={trader.id} value={trader.id}> {trader.trader_name} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>


                </div>
              </div>
            </>
          )}
        
          <div className="">
            <label style={{ color: '#c42026' }}><strong>Front & Back images of CNIC </strong></label>
          </div>
          <div className="row">

            <div className="row col-md-6 p-0 mx-auto mt-2 mb-4">
              {!inputFields.front_cnic ? (
                <>
                  <label htmlFor="imageInput">
                    <Button
                      variant="text"
                      component="label"
                      className="nic_uploadBtn"
                      sx={{ textTransform: "capitalize" }}
                    >
                      <div>
                        <AddAPhotoIcon />
                      </div>
                      Front CNIC
                      <input
                        hidden
                        className="form-control"
                        id="front_cnic"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleFrontChange}
                        type="file"
                        name="front_cnic"

                      />
                    </Button>

                  </label>
                  {cnicFrontImage && (
                    <p ref={errorRefFront} className="text-danger fs-6">{cnicFrontImage}</p>
                  )}
                </>
              ) : (
                <>
                  <div className="preview">
                    <div className="nicPreview">

                      {/* <img src={previewImage} alt="Preview" /> */}
                      {frontImg != null || frontImg != undefined ? (

                        <img src={frontUrl} alt="Preview" />



                      ) : (
                        <img src={previewFrontImage} alt="Preview" />

                      )
                      }

                      <button
                        className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                        onClick={handleFrontImageDelete}
                      >
                        <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                      </button>


                    </div>
                  </div>
                </>
              )}



            </div>
            <div className="row col-md-6 p-0 mx-auto mt-2 mb-4">
              {!inputFields.back_cnic ? (
                <>
                  <label htmlFor="imageInput">
                    <Button
                      variant="text"
                      component="label"
                      className="nic_uploadBtn"
                      sx={{ textTransform: "capitalize" }}
                    >
                      <div>
                        <AddAPhotoIcon />
                      </div>
                      Back CNIC
                      <input
                        hidden
                        className="form-control"
                        id="back_cnic"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleBackChange}
                        type="file"
                        name="back_cnic"

                      />
                    </Button>
                  </label>
                  {cnicBackImage && (
                    <p ref={errorRefBack} className="text-danger fs-6">{cnicBackImage}</p>
                  )}
                </>
              ) : (
                <>
                  <div className="preview">
                    <div className="nicPreview">
                      {/* <img src={previewImage} alt="Preview" /> */}
                      {backimg != null || backimg != undefined ? (

                        <img src={backUrl} alt="Preview" />
                      ) : (
                        <img src={previewBackImage} alt="Preview" />
                      )
                      }
                      <button
                        className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                        onClick={handleBackImageDelete}
                      >
                        <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div> <strong style={{ color: '#c42026' }} > Please choose mobile registration  </strong>
            <div className="mt-2 mb-2">
              self

              <Radio
                checked={inputFields.mob_registration == 1} onClick={(e) => radioHandler(1)}
                // onChange={handleRadioChange}
                value={inputFields.mob_registration}
                name="mob_registration"
                {...register('mob_registration')}
                inputProps={{ 'aria-label': 'A' }}
                sx={{ color: '#c42026!important' }}

              />
              {/* <Radio
                checked={status === 1} onClick={(e) => radioHandler(1)}
                onChange={handleRadioChange}
                value='1'
                name="mob_registration"
                inputProps={{ 'aria-label': 'A' }}
                sx={{ color: '#c42026!important' }}

              /> */}
              other
              <Radio
                checked={inputFields.mob_registration == 2} onClick={(e) => radioHandler(2)}
                // onChange={handleRadioChange}
                value={inputFields.mob_registration}
                name="mob_registration"
                {...register('mob_registration')}
                sx={{ color: '#c42026!important' }}
                inputProps={{ 'aria-label': 'B' }}

              />
              {/* <Radio
                checked={status === 2} onClick={(e) => radioHandler(2)}
                onChange={handleRadioChange}
                value="2"
                name="mob_registration"
                sx={{ color: '#c42026!important' }}
                inputProps={{ 'aria-label': 'B' }}

              /> */}

{regError && (
                    <p ref={errorReg} className="text-danger fs-6">{regError}</p>
                  )}

            </div>

            <div className="row col-md-12 p-0 mx-auto mb-4">
              <TextField

                id="outlined-error"
                label="Phone Number"
                name="phone"
                type="text"
                {...register('phone')}
                value={inputFields.phone}
                onChange={handleChange}
                fullWidth
                error />
              <p className="text-danger fs-6"> {errors.phone && <p>{errors.phone.message}</p>} </p>

            </div>



            {inputFields.mob_registration == 2 && (
              <div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div className="row col-md-12 p-0 mx-auto mb-4">
                      <TextField
                        sx={{ borderRight: 'none' }}
                        error
                        id="outlined-error"
                        label="Cnic of registered phone number"
                        name="relative_Cnic"
                        // style={{ display: errors.relative_Cnic ? 'block' : 'none' }}
                        // {...register('relative_Cnic')}           

                        onChange={handleChange}
                        value={inputFields.relative_Cnic}
                      />
                      <p className="text-danger fs-6"> {errors.relative_Cnic && <p>{errors.relative_Cnic.message}</p>} </p>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Relative relationship</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="relative_relation"
                        id="demo-simple-select"
                        {...register('relative_relation')}
                        value={inputFields.relative_relation}
                        label="Relative relationship"
                        error
                        onChange={handleChange}

                      >
                        <MenuItem >select relationship</MenuItem>
                        <MenuItem value='1'>Self</MenuItem>
                        <MenuItem value='2'>Father </MenuItem>
                        <MenuItem value='3'>Mother</MenuItem>
                        <MenuItem value='4'>Son</MenuItem>
                        <MenuItem value='5'>Daughter</MenuItem>
                        <MenuItem value='6'>Husband</MenuItem>
                        <MenuItem value='7'>Company</MenuItem>


                      </Select>
                    </FormControl>

                    <p className="text-danger fs-6"> {errors.relative_relation && <p>{errors.relative_relation.message}</p>} </p>


                  </div>
                  <div>
                    <label style={{ color: '#c42026' }}><strong>Proof of Relationship </strong></label>
                  </div>
                  <div class="row col-md-12 align-items-center ">

                    <div className="row col-md-12 p-0 mx-auto mt-2 mb-4">
                      {!inputFields.relation_proof ? (
                        <>
                          <label htmlFor="imageInput">
                            <Button
                              variant="text"
                              component="label"
                              className="nic_uploadBtn"
                              sx={{ textTransform: "capitalize" }}
                            >
                              <div>
                                <AddAPhotoIcon />
                              </div>
                              Proof of Relationship
                              <input
                                hidden
                                className="form-control"
                                id="relation_proof"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                {...register("relation_proof")}
                                name="relation_proof"
                                onChange={handleRelativeChange}


                              />
                            </Button>

                            {/* <button className="upload-button" onClick={handleImageUpload}>
            Upload
          </button> */}
                          </label>
                          {relationImageError && (
                            <p ref={errorRef} className="text-danger fs-6">{relationImageError}</p>
                          )}

                        </>
                      ) : (
                        <>
                          <div className="preview">
                            <div className="nicPreview">

                              {/* <img src={previewImage} alt="Preview" /> */}
                              {relImg != null || relImg != undefined ?
                                (

                                  <img src={relUrl} alt="Preview" />

                                ) : (
                                  <img src={previewRelationImage} alt="Preview" />
                                  // <img src={previewRelationImage} alt="Preview" />

                                )
                              }

                              <button
                                className="btn btn-color text-white d-flex align-items-center mt-2 m-auto"
                                onClick={handleRelativeImageDelete}
                              >
                                <ClearIcon sx={{ color: '#ffffff', fontSize: 18 }} className="me-2" /> Delete
                              </button>


                            </div>
                          </div>
                        </>
                      )}



                    </div>
                  </div>
                </div>

              </div>

            )}
          </div>
         
          <div className=" d-flex justify-content-between my-5">
            <Link to="/">
              <button className="btn btn pre-color"
                onClick={handledec}>
                <strong style={{ color: '#c42026' }}>  <img height={20} src={goback} /> Previous </strong></button>
            </Link>
            {/* <button className="btn btn  text-danger rounded"
              onClick={handleinc}>
              {step}/6                {isLoading && <div></div>}</button> */}

            {!isLoading ? (
              <button className="btn btn text-danger rounded">
                {step}/6
              </button>
            ) : (
              <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}



            <button type="submit" className="btn btn next-color text-white rounded"
            >

              Next <img height={20} src={gonext} /></button>
          </div>

        </div>
      </div>
    </form>


  )

}

export default StepOne;

